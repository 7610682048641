import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"

const ResumeNav = () => {
  /**********************/
  /*EXPANDED MENU TOGGLE*/
  /**********************/
  const [expanded, setExpanded] = useState(false)
  const setNavExpanded = () => {
    setExpanded(!expanded)
  }

  const closeNav = () => {
    setExpanded(false)
  }

  return (
    <Navbar
      className="navbar-light fixed-top py-3"
      id="mainNav"
      expand="lg"
      onToggle={setNavExpanded}
      expanded={expanded}
    >
      <div className="container-fluid px-3 px-md-5">
        <button
          className="navbar-brand js-scroll-trigger"
          tabIndex="0"
          onClick={() => {
            scrollTo("#page-top")
            closeNav()
          }}
        >
          Eric Alain
        </button>
        <Navbar.Toggle aria-controls="navbarResponsive">
          <FontAwesomeIcon
            icon={expanded ? ["fas", "times"] : ["fas", "bars"]}
            size="lg"
          />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="ml-4 ml-md-auto my-2 my-lg-0 px-">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#about" className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#skills" className="nav-link">
                Skills
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/#portfolio" className="nav-link">
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#contact" className="nav-link">
                Contact
              </Link>
            </li>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
export default ResumeNav
