import React from "react"
import { useStaticQuery, graphql } from "gatsby"

//Font awesome
import { config, library } from "@fortawesome/fontawesome-svg-core"

import {
  faBars,
  faEnvelope,
  faPhone,
  faTimes,
  faSquare
} from "@fortawesome/free-solid-svg-icons"

//Components
import Layout from "../components/layout"
import Seo from "../components/Seo/Seo"
import ResumeNav from "../components/Header/ResumeNav.js"
import Resume from "../components/Resume/Resume"

config.autoAddCss = false
library.add(faBars, faEnvelope, faPhone, faTimes, faSquare)

const ResumePage = ({ location, data }) => {
  const schemaQuery = useStaticQuery(
    graphql`
      query {
        meta: site {
          siteMetadata {
            description
            siteUrl
          }
        }
      }
    `
  )
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Eric Alain",
    description: schemaQuery.meta.siteMetadata.description,
    url: schemaQuery.meta.siteMetadata.siteUrl,
    sameAs: [
      "https://www.facebook.com/eric.alain1",
      "https://www.linkedin.com/in/eric-alain-87897184/"
    ],
    address: {
      "@type": "PostalAddress",
      addressRegion: "Ottawa",
      addressCountry: "CA"
    }
  }
  return (
    <Layout location={location} data={data}>
      <Seo schemaMarkup={schema} />
      <ResumeNav />
      <Resume />
    </Layout>
  )
}
export default ResumePage
