import * as React from "react"

import { Container, Col, Row } from "react-bootstrap"
import {
  profSummary,
  techSkills,
  genSkills,
  Certifications,
  education,
  references,
  renderWorkExperience
} from "./resumeContent"
import GradientOverlay from "../Extras/GradientOverlay"

const Resume = () => {
  return (
    <section className="page-section" id="resume">
      <Container>
        <Row>
          <Col xs="12" className="mb-3">
            <h1>Resume</h1>
            <h2>Professional summary</h2>
            <Row className="resume-summary mx-0">{profSummary}</Row>
            <h2>Technical skills and competencies</h2>
            <Row className="resume-technical-skills mx-0">
              <GradientOverlay
                direction="left"
                colorClassName="grad-1"
                opacity="op-40"
              />
              {techSkills}
            </Row>
            <h2>General skills and competencies</h2>
            <Row className="resume-general-skills mx-0">
              <GradientOverlay
                direction="right"
                colorClassName="grad-2"
                opacity="op-50"
              />
              {genSkills}
            </Row>
            <h2>Employment experience</h2>
            <Row className="resume-experience mx-0">
              <GradientOverlay
                direction="left"
                colorClassName="grad-4"
                opacity="op-50"
              />
              {renderWorkExperience()}
            </Row>
            <h2>Certifications</h2>
            <GradientOverlay
              direction="right"
              colorClassName="grad-3"
              opacity="op-60"
            />
            <Certifications />
            <h2>Education</h2>
            <Row className="mx-0">{education}</Row>
            <h2>References</h2>
            <Row className="mx-0">{references}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Resume
