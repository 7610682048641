import React from "react"
import Modal from "react-bootstrap/Modal"

const ResumeModal = ({ title, link, show, handleClose }) => {
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      className="resume-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img className="w-100" src={link} alt={`${title} certification`} />
      </Modal.Body>
    </Modal>
  )
}

export default ResumeModal
